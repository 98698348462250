import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article4/person4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(relativePath: { eq: "parent/article4/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article4/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Aleksandra Belta - TBC"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Aleksandra Belta-Iwacz</span>{' '}
                {/*<span className="personPosition">TBC</span>*/}
                <br />
                <ArticleAboutModal>
                  Psycholożka, terapeutka pedagogiczna, trenerka umiejętności
                  społecznych, praktyk metody Kids Skills i{'\u00A0'}Terapii
                  Skoncentrowanej na{'\u00A0'}Rozwiązaniach. Zafascynowana
                  psychologią dziecka, rodziny i{'\u00A0'}relacji. Na co dzień
                  wspiera dzieci i{'\u00A0'}ich rodziców podczas konsultacji.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Wybierając się na zakupy z{'\u00A0'}dzieckiem, warto pamiętać,
                że{'\u00A0'}dzieci bardzo łatwo zarażają się naszymi emocjami.
                Nasz strach, obawy przed kolejną sprzeczką czy błaganiem o
                {'\u00A0'}zakupy będą rodzić napięcie u{'\u00A0'}
                dziecka i{'\u00A0'}tym samym zwiększać prawdopodobieństwo
                wystąpienia kolejnej trudnej sytuacji. Dlatego zadbajmy o
                {'\u00A0'}swój spokój i{'\u00A0'}dobry nastrój! Dopiero wtedy
                ruszajmy na zakupy. Zadbajmy o{'\u00A0'}właściwe przygotowanie,
                ale także nauczmy się asertywności.
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row className="marginTop30">
            <Col xs={12} lg={6}>
              <h2 className="noMarginTop">Dobra aura</h2>
              <p>
                Trudno współpracować z{'\u00A0'}dziećmi, które czują się źle.
                Dlatego wybierając się na{'\u00A0'}zakupy, warto zatroszczyć się
                przede wszystkim o{'\u00A0'}ich dobry nastrój i{'\u00A0'}
                potrzeby fizjologiczne. Zaplanujmy zakupy na{'\u00A0'}czas, w
                {'\u00A0'}którym dziecko jest wypoczęte. Wchodząc do{'\u00A0'}
                sklepu, upewnijmy się, że{'\u00A0'}nasza pociecha ma{'\u00A0'}
                pusty pęcherz, nie jest głodna ani spragniona. Zadbanie o
                {'\u00A0'}te niepozorne aspekty pozwoli nam uniknąć wielu
                trudnych sytuacji.
              </p>
              <h2>Lista zakupów</h2>
              <p>
                Przygotowania do{'\u00A0'}wspólnych zakupów warto zacząć dużo
                wcześniej niż w{'\u00A0'}momencie przekraczania progu sklepu.
                Już w{'\u00A0'}domu wspólnie z{'\u00A0'}dzieckiem przygotujmy
                listę zakupów. Zadbajmy, by była ona czytelna dla każdego
                członka rodziny. Jeśli nasze dziecko jeszcze nie czyta, możemy
                zrobić ją{'\u00A0'}za{'\u00A0'}pomocną schematycznych, prostych
                rysunków, naklejek czy wycinanek z{'\u00A0'}gazet. Dzięki
                tworzeniu listy, stwarzamy okazje do{'\u00A0'}rozmowy o
                {'\u00A0'}tym, że{'\u00A0'}kupujemy wyłącznie to, co{'\u00A0'}
                jest nam potrzebne. W{'\u00A0'}sklepie łatwo możemy się odwołać
                do{'\u00A0'}wcześniejszych ustaleń, prosząc dziecko, by
                sprawdziło, czy{'\u00A0'}to, o{'\u00A0'}co{'\u00A0'}prosi, jest
                na naszej liście.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
                alt="Dobra aura"
              />
            </Col>
            <Col xs={12} sm={6}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <h2 className="noMarginTop">Lista marzeń</h2>
              <p>
                Warto okazać zainteresowanie dziecku, jeżeli o{'\u00A0'}coś nas
                prosi. Zignorowanie pociechy czy udawanie, że{'\u00A0'}nie
                słyszymy jej wołania, może spowodować jeszcze większą eskalację
                emocji. Często dzieci po{'\u00A0'}prostu chcą czegoś dotknąć,
                zobaczyć coś, pokazać nam, porozmawiać o{'\u00A0'}danej zabawce
                czy słodyczach. Podobnie jak dorośli, którzy mimo braku planów
                zakupowych lubią nieraz oglądać ogłoszenia domów na{'\u00A0'}
                sprzedaż, samochody czy ubrania. Dlatego warto się zatrzymać,
                zapytać dziecko, co{'\u00A0'}chciałoby nam pokazać, co{'\u00A0'}
                najbardziej podoba mu się w{'\u00A0'}danej zabawce. Jeśli bardzo
                chce ją{'\u00A0'}kupić, warto wpisać ją{'\u00A0'}na{'\u00A0'}
                dziecięcą listę marzeń. Taka lista może powstawać w{'\u00A0'}
                małym notesiku, w{'\u00A0'}notatkach telefonu rodzica czy po
                {'\u00A0'}prostu za{'\u00A0'}pomocą zdjęć robionych komórką.
                Wpisanie czegoś na{'\u00A0'}listę marzeń oznacza, że{'\u00A0'}
                może się to{'\u00A0'}stać prezentem urodzinowym czy świątecznym.
                Dzięki takiej strategii dziecko, któremu odmawiamy, nadal czuje
                się ważne i{'\u00A0'}słyszane.
              </p>

              <h2>Misja specjalna</h2>
              <p>
                Zachowanie dziecka zawsze jest komunikatem o{'\u00A0'}jakiejś
                potrzebie. Bardzo często tym, co{'\u00A0'}kryje się za{'\u00A0'}
                kolejnym „mamo, kup mi”, jest nuda. Możemy ją{'\u00A0'}
                wyeliminować, angażując dziecko w{'\u00A0'}misje specjalne
                podczas zakupów. Może to być np. odczytywanie kolejnych punktów
                z{'\u00A0'}listy zakupów albo samodzielne poszukiwanie
                wskazanego przez rodzica produktu.
              </p>
            </Col>
            <Col xs={12}>
              <h2>Umiejętność odmawiania</h2>
              <p>
                „Kup mi to” padające z{'\u00A0'}ust dziecka potrafi bardzo
                szybko wyprowadzić nas z{'\u00A0'}równowagi. Odbieramy je
                {'\u00A0'}często jako dziecięce zachcianki czy kaprysy. Od
                {'\u00A0'}razu uruchamiają się w{'\u00A0'}nas oceniające,
                niewspierające myśli „znowu to{'\u00A0'}samo”, „on nigdy nie
                docenia tego, co{'\u00A0'}już ma, zawsze chce więcej”, „nie umie
                się zachować”. Warto powiedzieć sobie stop, dlatego że{'\u00A0'}
                takie przekonania nikomu nie pomagają. Dzieci nie są{'\u00A0'}
                złośliwe, nawet jeśli prośbie o{'\u00A0'}zakup towarzyszy krzyk
                czy płacz, jest to{'\u00A0'}informacja wyłącznie o{'\u00A0'}tym,
                że{'\u00A0'}w{'\u00A0'}tym momencie dziecko inaczej nie potrafi
                zakomunikować nam swojej potrzeby. Dlatego warto dzieciom
                podpowiedzieć: „płaczesz, bo to{'\u00A0'}było dla ciebie bardzo
                ważne”, „widzę, że{'\u00A0'}naprawdę ci{'\u00A0'}na tym
                zależało”. Odmawianie dzieciom jest dla nich cenną lekcją
                rozumienia granic innych. Zawsze, kiedy czujemy, że{'\u00A0'}nie
                chcemy zgodzić się na{'\u00A0'}prośbę dziecka, mamy prawo
                powiedzieć „nie”, nawet jeśli to{'\u00A0'}dla malucha trudne.
                Warto jednak zwrócić uwagę, by{'\u00A0'}odmowie towarzyszył
                prawdziwy argument. Usuńmy z{'\u00A0'}naszego słownika nic
                niewnoszące „nie, bo nie” czy lekceważące „a{'\u00A0'}ja bym
                chciała gwiazdkę z{'\u00A0'}nieba”. Warto postawić{'\u00A0'}na
                rozmowę. Możemy odpowiedzieć, że zależy nam na{'\u00A0'}
                kupowaniu tego, czego jest na{'\u00A0'}naszej liście, tego,
                czego naprawdę potrzebujemy, że{'\u00A0'}chcemy oszczędzać albo
                uważamy, że
                {'\u00A0'}dana zabawka nie jest warta swojej ceny. Ważne, by
                nasze „nie” nie było odebrane przez dziecko jako coś przeciwko
                niemu, a{'\u00A0'}właśnie jako troska o{'\u00A0'}nasze własne
                granice. Taka odpowiedź nie sprawi oczywiście, że{'\u00A0'}
                dziecko przyjmie odmowę z{'\u00A0'}pokorą, naszą rolą jest więc
                przyjąć jego emocje i{'\u00A0'}wesprzeć je. Nawet jeśli nie
                możemy dostać od{'\u00A0'}życia tego, co{'\u00A0'}chcemy, samo
                zauważenie przez kogoś, że{'\u00A0'}było to{'\u00A0'}dla nas
                ważne, okazuje się{'\u00A0'}niezwykle wspierające. Słyszenie
                odmowy na{'\u00A0'}prośby zakupowe to dla dzieci cenna lekcja
                odraczania swoich potrzeb i{'\u00A0'}radzenia sobie z{'\u00A0'}
                frustracją. Nabywanie tych umiejętności w{'\u00A0'}relacji z
                {'\u00A0'}rodzicami pozwala robić to w{'\u00A0'}bezpiecznych,
                kontrolowanych warunkach. Nauka jest jednak zawsze procesem,
                dlatego najważniejszą wskazówką dla rodziców powinno być
                uzbrojenie się w{'\u00A0'}ogromne pokłady cierpliwości.
              </p>
              <p>Autor: Aleksandra Belta-Iwacz</p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
