import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article4 from '../../components/parent/article4'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article4/article-header4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool3: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="Mamo, kup mi! Jak reagować na dziecięce prośby"
        description="Zakupy z dzieckiem bywają wyzwaniem. Kolorowe przedmioty na półkach kuszą małych konsumentów, a dorośli co i rusz muszą się mierzyć z błagalnym „kup mi” wykrzykiwanym przez dzieci. Jak sobie z tym poradzić? Co możemy zrobić, żeby ułatwić sobie zakupy z dziećmi?"
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={
          <>
            Mamo, kup{'\u00A0'}mi!
            <br />
            Jak{'\u00A0'}reagować na{'\u00A0'}dziecięce prośby
          </>
        }
        desc={
          <p>
            Zakupy z{'\u00A0'}dzieckiem bywają wyzwaniem. Kolorowe
            <br />
            przedmioty na{'\u00A0'}półkach kuszą małych konsumentów,
            <br /> a{'\u00A0'}dorośli co i{'\u00A0'}rusz muszą się mierzyć z
            {'\u00A0'}błagalnym
            <br /> „kup mi” wykrzykiwanym przez dzieci. Jak sobie
            <br /> z{'\u00A0'}tym poradzić? Co możemy zrobić, żeby zakupy
            <br />z{'\u00A0'}dziećmi były łatwiejsze?
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article4 />
      <UsefulTools
        items={[
          {
            title: 'Gry',
            subtitle: 'Budżet Samodzielniaka / Plansza i instrukcja',
            image: data.Tool1,
            link: '/parent/Budzet_Samodzielniaka_plansza_instrukcja.pdf',
          },
          {
            title: 'Gry',
            subtitle: 'Finansowe kalambury',
            image: data.Tool1,
            link: '/parent/Finansowe%20kalambury.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Kolorowanka',
            image: data.Tool3,
            link: '/parent/Kolorowanka%201.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
